<template>
	<div class="Personal">
		<div
			v-if="isInfo"
			class="personal-information bgfff br4 w100 h100"
			style="overflow: hidden"
		>
			<div class="title ml32">
				<!-- 面包屑 start -->
				<div class="bread flex align-center font-33 ptb24">
					您现在的位置：<el-breadcrumb
						separator="/"
						separator-class="el-icon-arrow-right"
					>
						<el-breadcrumb-item
							@click.stop.prevent.native="changeIsInfo(false)"
							class="is-link"
							>个人中心</el-breadcrumb-item
						>
						<el-breadcrumb-item>修改信息</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<!-- 面包屑 end -->
			</div>
			<div class="con">
				<div class="item head flex align-center">
					<span class="item-name">头像：</span>
					<div class="item-div">
						<el-upload
							:accept="'.png,.jpg,.jpeg'"
							class="upload-demo avatar"
							:action="'/api' + $API.uploadFile"
							list-type="picture"
							:show-file-list="false"
							:on-success="onSuccess"
							:before-upload="beforeUpload"
						>
							<div class="change pr00">
								<img
									:src="cUserInfo.head_img || require('@/assets/logo.png')"
								/>
								<span>点击更换</span>
							</div>
						</el-upload>
					</div>
				</div>
				<div class="item sex flex align-center">
					<span class="item-name">性别：</span>
					<div class="item-div">
						<el-radio-group v-model="cUserInfo.sex">
							<el-radio :label="1">男</el-radio>
							<el-radio :label="2">女</el-radio>
						</el-radio-group>
					</div>
				</div>
				<div class="item flex align-center">
					<span class="item-name">ID：</span>
					<div class="item-div">
						<el-input disabled :value="cUserInfo.id" placeholder="" />
					</div>
				</div>
				<div class="item flex align-center">
					<span class="item-name">昵称：</span>
					<div class="item-div">
						<el-input
							v-model="cUserInfo.user_nickname"
							maxlength="12"
							placeholder="请输入用户名(最多12字)"
							clearable
						/>
					</div>
				</div>
				<!-- <div class="item flex align-center ">
					<span class="item-name">联系方式：</span>
					<div class="item-div">
						<el-input
							:value="cUserInfo._mobile"
							disabled
							placeholder="请输入联系方式"
							clearable
						/>
					</div>
				</div> -->

				<div class="item flex align-center">
					<span class="item-name">生日：</span>
					<div class="item-div">
						<el-date-picker
							style="width: 100%"
							v-model="cUserInfo.birthday"
							type="date"
							placeholder="选择日期"
							clearable
							value-format="yyyy-MM-dd"
						>
						</el-date-picker>
					</div>
				</div>
				<div class="item btn-wrap flex align-center" style="margin-top: 64px">
					<span class="item-name"></span>
					<div class="item-div">
						<button type="button" @click="changeUserInfo">编辑修改</button>
					</div>
				</div>
			</div>
		</div>

		<div v-else class="other">
			<!-- 个人信息 start -->
			<div class="top flex pr00 br4">
				<div class="left pr00">
					<img
						:src="userInfo.head_img"
						alt="头像"
						class="head hande"
						@click="changeIsInfo(true)"
					/>
					<img
						v-if="userInfo.user_level > 0"
						class="vip"
						src="@/assets/my/vip.png"
					/>
				</div>
				<div class="right">
					<div class="name flex align-center justify-between">
						<span class="fs18 fw-7 font-33 hande" @click="changeIsInfo(true)">{{
							userInfo.user_nickname
						}}</span>
						<router-link
							:to="{
								name: 'Integral',
								params: {
									curType: 'sign',
								},
							}"
						>
							<img
								class="sign cursor"
								src="@/assets/imgs/home-sign.png"
								alt="签到"
							/>
						</router-link>
					</div>
					<div class="code-wrap flex mt16">
						<div class="bgfff flex align-center fs12 font-33">
							邀请码：{{ userInfo.invite_code }}<span class="line">|</span
							><span
								v-clipboard:copy="userInfo.invite_code"
								v-clipboard:success="copySuccess"
								v-clipboard:error="copyError"
								class="cursor plr24"
								>复制</span
							>
						</div>
					</div>
					<div v-if="userInfo.user_level > 0" class="vip mt16 font-33">
						有效期至 {{ userInfo.vip_last_time }}
						<i @click="jump('Vip')" class="iconfont cursor">&#xe600;</i>
					</div>
					<div v-else class="vip mt16 font-33">
						未开通会员
						<i @click="jump('Vip')" class="iconfont cursor">&#xe600;</i>
					</div>
				</div>

				<div @click="jump('Vip')" class="btn fs16 font-ff">
					{{ userInfo.user_level > 0 ? "立即续费" : "立即开通" }}
				</div>
			</div>
			<!-- 个人信息 end -->
			<div class="con bgfff br4 mt24">
				<ul>
					<li
						@click="jump('Integral')"
						class="mlr32 ptb32 flex align-center cursor"
					>
						<div class="left flex align-center fs16 font-33">
							<img
								class="mr24"
								src="@/assets/imgs/about-personal-integral.png"
							/>我的积分：{{ userInfo.score }}
						</div>
						<i class="el-icon-arrow-right"></i>
					</li>
					<li
						@click="changeIsInfo(true)"
						class="mlr32 ptb32 flex align-center cursor"
					>
						<div class="left flex align-center fs16 font-33">
							<img
								class="mr24"
								src="@/assets/imgs/about-personal-my.png"
							/>个人信息
						</div>
						<i class="el-icon-arrow-right"></i>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from "vuex";
export default {
	name: "Personal",
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	data() {
		return {
			isInfo: false, // 是否修改个人信息
			cUserInfo: {}, // 旧的信息
			loading: false,
		};
	},
	methods: {
		copySuccess() {
			this.$message.success("复制成功");
		},
		copyError() {
			this.$message.error("复制失败");
		},
		jump(name) {
			this.$router.push({
				name,
			});
		},
		// 上传完成
		onSuccess(e) {
			this.$API.changeUserInfo({ head_img: e.data[0].id }).then((res) => {
				if (res.code == 1) {
					this.cUserInfo.head_img = res.data.userinfo.head_img;
					let obj = { ...this.userInfo, ...res.data.userinfo };
					this.$store.commit("login", obj);
				}
			});
		},
		// 改变 是否修改信息
		changeIsInfo(sign) {
			this.isInfo = sign;
			if (sign) {
				this.cUserInfo = { ...this.userInfo };
			} else {
				this.cUserInfo = {};
			}
		},
		// 上传之前
		beforeUpload(file) {
			const isPNG = file.type === "image/png";
			const isJPG = file.type === "image/jpg";
			const isJPEG = file.type === "image/jpeg";

			const isLt10M = file.size / 1024 / 1024 < 10;

			if (!isPNG && !isJPG && !isJPEG) {
				this.$message.error("上传图片只能是 PNG或者JPG或者JPEG 格式!");
			}
			if (!isLt10M) {
				this.$message.error("上传图片大小不能超过 10MB!");
			}
			return (isPNG || isJPG || isJPEG) && isLt10M;
		},
		// 修改个人信息
		changeUserInfo() {
			let { cUserInfo = {}, loading } = this;
			let { user_nickname } = cUserInfo;

			if (loading) {
				return false;
			}

			if (!user_nickname || !user_nickname.toString().trim()) {
				this.$message.warning("请输入用户名");
				return false;
			}

			if (user_nickname.length > 12) {
				this.$message.warning("请输入正确的用户名");
				return false;
			}

			if (!user_nickname || !user_nickname.toString().trim()) {
				this.$message.warning("请输入用户名");
				return false;
			}

			this.loading = true;

			this.$API
				.changeUserInfo({
					...this.cUserInfo,
				})
				.then((res) => {
					this.loading = false;
					if (res.code == 1) {
						this.$message.success("信息修改成功");
						let obj = { ...this.userInfo, ...res.data.userinfo };
						this.$store.commit("login", obj);
						this.isInfo = false;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					this.loading = false;
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}

	.sex {
		.el-radio__inner {
			background: initial;
			border-color: #bfbfbf;

			&:hover {
				border-color: #ff8300;
			}

			&:after {
				background-color: #bfbfbf;
				width: 8px;
				height: 8px;
				// transform: translate(-50%, -50%) scale(1);
			}
		}

		.el-radio.is-checked .el-radio__inner {
			background-color: transparent;
			border-color: #ff8300;

			&:after {
				background-color: #ff8300;
			}
		}

		.el-radio.is-checked .el-radio__label {
			color: #ff8300;
		}
	}
}

.Personal {
	@extend .w100;
	@extend .h100;

	> .other {
		@extend .w100;
		@extend .h100;
		display: flex;
		flex-direction: column;

		.top {
			width: 100%;
			overflow: hidden;
			background: url(../../assets/imgs/about-personal-bg.png) no-repeat 100%
				100%;
			padding: 40px 32px 30px 32px;

			.left {
				width: 72px;
				height: 72px;

				.head {
					width: 100%;
					height: 100%;
					border-radius: 50%;
				}

				.vip {
					width: 18px;
					height: 26px;
					position: absolute;
					right: 0;
					bottom: 0;
				}
			}

			.right {
				padding-left: 12px;
				flex: 1;

				.name {
					.sign {
						width: 36px;
						height: 38px;
					}
				}

				.code-wrap {
					> div {
						border-radius: 6px;
						padding: 6px 14px;
						padding-right: 0;

						.line {
							margin-left: 63px;
							color: #b8b8b8;
						}
					}
				}

				.vip {
					i {
						margin-left: 8px;
						font-size: 16px;
					}
				}
			}

			.btn {
				border-radius: 200px;
				padding: 8px 32px;
				cursor: pointer;
				position: absolute;
				right: 32px;
				bottom: 26px;
				background: linear-gradient(to right, #e6b57b, #c99556);
			}
		}

		.con {
			width: 100%;
			flex: 1;

			ul {
				li {
					border-bottom: 1px solid #eeeeee;

					.left {
						flex: 1;

						img {
							width: 40px;
							height: 40px;
						}
					}
				}
			}
		}
	}

	.personal-information {
		@extend .w100;
		@extend .h100;

		.con {
			flex: 1;
			overflow: auto;

			.item {
				padding-top: 32px;

				.item-name {
					width: 140px;
					font-size: 14px;
					font-weight: 400;
					color: #999999;
					text-align: right;
					padding-right: 24px;
				}

				.item-div {
					width: 322px;

					button {
						border-radius: 100px;
						width: 240px;
						height: 48px;
						background-color: #ff8300;
						font-size: 16px;
						font-weight: 400;
						color: #ffffff;
					}
				}

				&.head {
					padding-top: 40px;

					.change {
						border-radius: 50%;
						width: 100px;
						height: 100px;
						border: 1px solid #d9d9d9;
						overflow: hidden;
						cursor: pointer;

						img {
							width: 100px;
							height: 100px;
							border-radius: 50%;
						}

						span {
							position: absolute;
							bottom: 0;
							left: 0;
							background-color: rgba(0, 0, 0, 0.6);
							font-size: 12px;
							font-weight: 400;
							color: #ffffff;
							width: 100%;
							text-align: center;
							height: 28px;
							line-height: 28px;
						}
					}
				}

				&.sex {
					padding-top: 30px;
				}
			}
		}
	}
}
.hande:hover {
	cursor: pointer;
}
</style>
